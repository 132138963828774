import { Injectable, ViewChild, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError, finalize, switchMap, filter, take, retry } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  BOT_TOKEN_INFO,
  USER_INFO,
  USER_TOKEN_INFO,
  IS_CUSTOM_LOGIN,
  USER_CLIENT_TOKEN_INFO,
  USER_CLIENT_REFRESH_INFO,
} from '@algomusinc/domain';
import {
  AuthService,
  LocalstorageService,
  LoaderService,
  SnackbarService,
  ConfigurationService,
  NotificationService,
} from '@algomusinc/services';
import { environment } from '../environments/environment';
import { OktaAuthService } from '@algomusinc/okta';
import { SnackbarComponent } from '@algomusinc/components';
import { LoadingBarService } from '@ngx-loading-bar/core';
@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  private oktaAuthService!: OktaAuthService;

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  FILE_UPLOAD_URLS: any = [
    `${environment.AppDocument}/UploadFiles`,
    `${environment.AppFBForcast}/Import`,
    `${environment.AppFBForcast}/ImportVerticalLayout`,
    `${environment.AppCommonCode}/DocumentAdd`,
    `${environment.AppAccount}/AddClientConfigurationOnboarding`,
    `${environment.AppDocument}/UploadFiles`,
    `${environment.GenAiapi}/ask`,
    `${environment.GenAiapi}/process_csv`,
    `${environment.GenAiapi}/process_link`,
    `${environment.GenAiapi}/get_col_description`,
    `${environment.AppChildItem}/uploadimage`,
    `${environment.AppMasterItem}/import`,
    `${environment.AppChildItem}/import`,
    `${environment.AppItemPromotion}/ImportNewItemPromotionsList`,
    `${environment.AppItemPromotion}/import`,
    `${environment.AppItemPromotionPlanner}/import`,
    `${environment.AppItemPromotionPlanner}/importpromotiondetail`,
    `${environment.channelMessage}/Add`,
    `${environment.PMS}/PurchaseRequest/uploaddoc`,
    `${environment.AlgocoreApiUrlDatabricks}/v2/uploads/data`
  ];

  constructor(
    public storage: LocalstorageService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public authService: AuthService,
    public snackbar: SnackbarService,
    private loadingBar: LoadingBarService,
    public config: ConfigurationService,
    public notif: NotificationService,
    private injector: Injector
  ) {
    if (environment.okta.enableOkta) {
      this.oktaAuthService = <OktaAuthService>(
        this.injector.get(OktaAuthService)
      );
    }
  }
  public hideLoaderList: string[] = [
    `${environment.AppFBSpreading}/SpreadingForecastAddCommitMeasures`,
  ];
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    let token: any = this.storage.get(USER_TOKEN_INFO);
    let userClienttoken: any = this.storage.get(USER_CLIENT_TOKEN_INFO);
    const isCustomLogin = this.storage.get(IS_CUSTOM_LOGIN);
    if (isCustomLogin) {
      token = this.storage.get(USER_TOKEN_INFO);
    } else if (this.oktaAuthService) {
      token = this.oktaAuthService.oktaAuth.getAccessToken();
    }
      if (userClienttoken) {
        request = request.clone({
          headers: request.headers.set(
            'X-User-Client-Authorization',
            userClienttoken
          ),
        });
      }
      if (token) {
        if (request.url === environment.AppTokenGenerate) {
          request = request.clone({
            headers: request.headers.set(
              'Authorization',
              'Bearer ' + environment.BotSecretKey
            ),
          });
        } else if (
          request.url.startsWith(environment.AppConversations) ||
          request.url === environment.AppTokenRefresh
        ) {
          const conversatonToken: string = this.storage.get(BOT_TOKEN_INFO);
          request = request.clone({
            headers: request.headers.set(
              'Authorization',
              'Bearer ' + conversatonToken
            ),
          });
        } else if (
          request.url !==
          `https://login.microsoftonline.com/dd70a646-992f-4f0e-a15e-d9c554e1f576/oauth2/v2.0/token`
        ) {
          request = request.clone({
            headers: request.headers.set('Authorization', 'Bearer ' + token),
          });
        }
      }
      if (
        !(this.isFileObjectInFormData(request.body)) &&
        !(this.FILE_UPLOAD_URLS.includes(request.url))
      ) {
        if (!request.headers.has('Content-Type')) {
          request = request.clone({
            headers: request.headers.set('Content-Type', 'application/json'),
          });
        }
        request = request.clone({
          headers: request.headers.set('Accept', 'application/json'),
        });
        request = request.clone({
          headers: request.headers.set('x-application-key', 'AdminPortal'),
        });
      } else {
        request = request.clone({
          headers: request.headers.set('Accept', 'application/json'),
        });
      }
    const self = this;
    const ref = this.loadingBar.useRef('http');
    if (this.hideLoaderList.includes(request.url)) {
      ref.complete();
    } else {
      ref.start();
    }

    return next.handle(request).pipe(
      finalize(() => {
        ref.complete();
      }),
      catchError((error: any): any => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            if (userClienttoken) {
              return this.handleRefreshToken(request, next);
            } else {
              localStorage.clear();
              window.location.reload();
              return throwError(() => error);
            }

          } else if (
            error &&
            error.error &&
            error.error.error &&
            error.error.error.indexOf('Multiple planner') > -1
          ) {
            this.snackbar.error(
              SnackbarComponent,
              'A new planner session has been opened.',
              'Multiple Planner Operations'
            );
            this.config.updateForecastList.next(request);
          } else if (self.spinner) {
            self.spinner.hide();
          }
          return throwError(() => error);
        }
      })
    );
  }

  private addToken(request: HttpRequest<any>): HttpRequest<any> {
    // Create a new HttpHeaders instance with the existing headers and new headers
    let headers = request.headers
      .set('Authorization', `Bearer ${this.storage.get(USER_TOKEN_INFO)}`);

      headers.set('X-User-Client-Authorization', `${this.storage.get(USER_CLIENT_TOKEN_INFO)}`);

    // Return a new HttpRequest with the updated headers
    return request.clone({
      headers: headers
    });
  }

  private handleRefreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.isRefreshing) {
      this.spinner.show();
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      // Query all input elements using the selector
      Array.from(document.querySelectorAll('input')).forEach(el => el.blur())

      // Call your authentication service to refresh the token
      return this.authService.refreshAccessToken().pipe(
        catchError((error) => {
          this.spinner.hide();
          this.isRefreshing = false;
          return this.handleUnAuthError(error, error?.url);
        }),
        switchMap((tokens: any) => {
          this.storage.set(USER_TOKEN_INFO, tokens.token);
          this.storage.set(USER_CLIENT_TOKEN_INFO, tokens.token);
          this.storage.set(USER_CLIENT_REFRESH_INFO, tokens.refreshToken);
          this.isRefreshing = false;
          this.spinner.hide();
          this.refreshTokenSubject.next(tokens.token);

          // Retry the original request with the new token
          return next.handle(this.addToken(request)).pipe(
            catchError((error) => {
              // Handle retry error here if needed
              return this.handleUnAuthError(error);
            })
          );
        })
      );
    } else {
      // If already refreshing, wait for the token to be updated
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(() => {
          // Retry the original request with the new token
          return next.handle(this.addToken(request)).pipe(
            catchError((error) => {
              // Handle retry error here if needed
              return this.handleUnAuthError(error);
            })
          );
        })
      );
    }
  }

  private handleUnAuthError(error: any, url = '') {
    if (error instanceof HttpErrorResponse) {
      if (error.status === 401 || url.includes('refreshToken')) {
        localStorage.clear();
        window.location.reload();
      }
    }
    return throwError(() => error);
  }

  isFileObjectInFormData(body: any) {
    let isFileInBody: boolean = false;
    if (body instanceof FormData) {
      isFileInBody = true;
    }
    return isFileInBody
  }
}
